import React, { ReactElement } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import { VideoContent } from "./VideoContent";
import { SoundtrackCarousel } from "./SoundtrackCarousel";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = (): ReactElement => (
  <div>
    <Helmet>
      <title>Mixopia | Home</title>
    </Helmet>
    <VideoContent name="Themes" />
    <NarrowContent className="my-3 text-center">
      <h1>Soundtrack your life</h1>
      <p>
        Soundtrack your next event or gathering with themed, collaborative
        playlists to relive music memories and create new ones.
      </p>
      <ActionsWrapper>
        <a className="btn btn-primary" href="https://play.mixopia.com/events">
          Create your first event
        </a>
        <a
          className="btn btn-secondary"
          href="https://play.mixopia.com/authentication/login"
        >
          Login
        </a>
      </ActionsWrapper>
    </NarrowContent>
    <Container className="home-howto">
      <h1>How To Mixopia</h1>
      <Row>
        <Col>
          <h2>01</h2>
          <p>
            Enter date, time
            <br />
            and event details
          </p>
        </Col>
        <Col>
          <h2>02</h2>
          <p>
            Choose your
            <br />
            playlist themes
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>03</h2>
          <p>
            Send Mixopia event
            <br />
            link to guests
          </p>
        </Col>
        <Col>
          <h2>04</h2>
          <p>
            Select tracks
            <br />
            for each theme
          </p>
        </Col>
      </Row>
      <h1>Enjoy everyone&apos;s music!</h1>
    </Container>
    <SoundtrackCarousel />
    <NarrowContent className="my-3 text-center">
      <h1 className="alert-header">Mixopia</h1>
      <p className="alert-text">
        Mixopia is a not-for-profit passion project, born from our belief in the connective power of music.</p>
      <p className="alert-text">
        Through Mixopia, it is easy for everyone to contribute to the music at events & gatherings: encouraging storytelling, dancing, and singing along.
      </p>
      <p className="alert-text">
        We love feedback, so please let us know about your Mixopia experience at <a href="mailto:support@mixopia.com">support@mixopia.com</a>.
      </p>
    </NarrowContent>

    <ActionsWrapper>
      <a
        className="btn btn-primary"
        href="https://play.mixopia.com/authentication/register"
      >
        Register Now
      </a>
    </ActionsWrapper>
    <VideoContent name="Boombox" />
    <ActionsWrapper>
      <Button tag={Link} color="primary" to="/explore">
        Explore More
      </Button>
    </ActionsWrapper>
  </div>
);

export default Home;
